<template>
  <div>
    <process-table v-model="value" :readOnly="readOnly"></process-table>
  </div>
</template>

<script>
import processTable from './process-table.vue';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    careerTypes: []
  }),

  computed: {},

  mounted() {
    const careerTypes = [
      ...new Set(this.value.map((item) => item.careerTargetType))
    ];

    this.careerTypes = careerTypes.sort((a, b) => {
      return a - b;
    });
  },

  components: {
    processTable
  }
};
</script>
