<template>
  <v-dialog v-model="dialog" max-width="1024px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn v-on="{ ...dialog }" v-bind="attrs">
        <slot name="button">
          <v-icon>mdi-menu</v-icon>
        </slot>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">
          Mitarbeitende für eine Beförderung vorschlagen
        </span>
      </v-card-title>
      <v-card-text>
        <p v-if="targetTalkround">
          Zeitpunkt der Beförderung: {{ targetTalkround.text }}
        </p>

        <v-form ref="promotionUserForm">
          <v-autocomplete
            v-model="user"
            filled
            label="Mitarbeitende auswählen"
            :items="users"
          />
          <v-autocomplete
            v-model="targetPosition"
            filled
            label="Zielposition wählen"
            :items="positions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          <slot name="close-button">{{ $t('close') }}</slot>
        </v-btn>

        <v-btn color="blue darken-1" text @click="confirm">
          <slot name="confirm-button">{{ $t('save') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    talkrounds: {
      type: Array,
      required: true
    },

    correlationId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    positions: [],
    users: [],
    user: null,
    targetPosition: null,
    targetTalkround: null
  }),

  computed: {
    ...mapGetters({
      getEmployees: 'users/getOverviewUsers'
    })
  },

  watch: {
    async dialog() {
      if (this.dialog) {
        await this.setPositions();
        await this.setEmployees();
      }
    }
  },

  mounted() {
    this.targetTalkround = this.getCurrentTalkround();
  },

  methods: {
    ...mapActions({
      addPromotionUser: 'careerDevelopment/addPromotionUser',
      listPositions: 'careerDevelopment/listDevelopmentPositions',
      fetchEmployees: 'users/fetchMyEmployees'
    }),

    close() {
      this.dialog = false;
    },

    async confirm() {
      const talkround = this.targetTalkround;
      const targetDate = new Date(talkround.targetDate);

      const result = await this.addPromotionUser({
        userId: this.user,
        targetPositionId: this.targetPosition,
        targetDate: targetDate,
        talkroundId: this.targetTalkround.id,
        correlationId: this.correlationId
      });

      if (result.success) {
        this.$emit('userAdded');
        this.dialog = false;
        this.$refs.promotionUserForm.reset();
      }
    },

    getCurrentTalkround() {
      const activeTalkrounds = this.talkrounds.filter((x) => x.isActive);
      if (activeTalkrounds.length === 0) {
        return {
          id: null,
          text: ''
        };
      }

      return activeTalkrounds[0];
    },

    async setEmployees() {
      if (this.users.length === 0) {
        await this.fetchEmployees();
        this.users = this.getEmployees.map((x) => ({
          text: `${x.firstname} ${x.lastname}`,
          value: x.id
        }));
      }
    },

    async setPositions() {
      const vm = this;
      if (this.positions.length === 0) {
        const data = await this.listPositions();
        this.positions = data.map((x) => {
          return {
            text: vm.localize(x.name),
            value: x.id
          };
        });
      }
    }
  }
};
</script>
