<template>
  <div class="mb-8">
    <div class="table">
      <div class="table-header">
        <v-row class="d-none d-md-flex">
          <v-col cols="4" md="1" class="caption">{{
            $t('employeeDetails.shorthand')
          }}</v-col>
          <v-col cols="8" md="2" class="caption">{{
            $t('employeeDetails.name')
          }}</v-col>
          <v-col cols="8" md="2" class="caption">{{
            $t('employeeDetails.currentPosition')
          }}</v-col>

          <v-col cols="12" md="2" class="caption">{{
            $t('careerDevelopment.targetPosition')
          }}</v-col>

          <v-col cols="12" md="1" class="caption"> Zeitpunkt </v-col>

          <v-col cols="12" md="2" class="caption">
            {{ $t('employeeDetails.supervisor') }}
          </v-col>

          <v-col cols="12" md="2" class="caption"> Status </v-col>
        </v-row>
      </div>

      <div class="table-body">
        <process-item
          v-for="processItem in value"
          :key="`promotion_process_${processItem.id}`"
          :item="processItem"
          :readOnly="readOnly"
        ></process-item>
      </div>
    </div>
  </div>
</template>

<script>
import processItem from './process-table-item.vue';

export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    processItem
  }
};
</script>
