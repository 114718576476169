<template>
  <v-row @click="expanded = !expanded" class="promotion-process__item-row">
    <v-col cols="4" md="1">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('employeeDetails.shortHand') }}</strong>
      </p>
      {{ item.userShorthand }}
    </v-col>

    <v-col cols="8" md="2">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('employeeDetails.name') }}</strong>
      </p>

      {{ item.userName }}
    </v-col>

    <v-col cols="8" md="2">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('employeeDetails.currentPosition') }}</strong>
      </p>

      {{ localize(item.currentPositionName) }}
    </v-col>

    <v-col cols="12" md="2">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('careerDevelopment.targetPosition') }}</strong>
      </p>

      {{ localize(item.targetPositionName) }}
    </v-col>

    <v-col cols="12" md="1">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>Zeitpunkt</strong>
      </p>

      {{ $d(new Date(item.targetDate), 'date') }}
    </v-col>

    <v-col cols="12" md="2">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>
          {{ $t('employeeDetails.supervisor') }}
        </strong>
      </p>

      {{ item.supervisorName }}
    </v-col>

    <v-col cols="12" md="2">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong> Status </strong>
      </p>

      <v-chip label color="orange"> Offen </v-chip>
    </v-col>

    <!-- <v-col cols="12" md="1">
      <v-btn
        v-if="item.promotionFormFeedbackId && !item.promotionFormSubmitted"
        small
        :to="{
          name: 'FeedbackForm',
          params: { id: item.promotionFormFeedbackId }
        }"
        >{{ $t('careerDevelopment.nominationForm') }}</v-btn
      >

      <template
        v-else-if="item.promotionFormFeedbackId && item.promotionFormSubmitted"
      >
        <div>
          <v-btn
            small
            :to="{
              name: 'FeedbackForm',
              params: { id: item.promotionFormFeedbackId }
            }"
          >
            <v-icon small class="mr-2">mdi-clipboard-check</v-icon>
            {{ $t('careerDevelopment.goToResult') }}
          </v-btn>
        </div>
      </template>
    </v-col> -->

    <v-expand-transition>
      <v-col cols="12" v-show="expanded" class="expanded-content">
        <v-row v-if="item.mafegDataAvailable">
          <v-col cols="6" md="2">
            <p class="ma-0 muted">
              <strong
                >{{ $t(`feedbacks.processTypes.${mafegProcessType}`) }}
                {{ $t('feedbacks.status') }}
              </strong>
            </p>
          </v-col>
          <v-col cols="6" md="3">
            <v-chip
              label
              :color="getStatusColor(item.mafegStatus, mafegProcessType)"
              class="ml-2"
            >
              {{ localizeStatus(mafegProcessType, item.mafegStatus) }}
            </v-chip>
          </v-col>
          <v-col cols="12" md="7">
            <p class="ma-0 muted">
              <strong
                >{{ $t(`feedbacks.processTypes.${mafegProcessType}`) }}
                {{ $t('feedbacks.approvedOn') }}</strong
              >
              {{ item.mafegConfirmationDate }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="item.promotionFormProcessId">
          <v-col cols="6" md="2">
            <p class="ma-0 muted">
              <strong>Nominierungsformular</strong>
            </p>
          </v-col>
          <v-col cols="6" md="3">
            <v-chip
              label
              :color="
                getStatusColor(item.promotionFormStatus, promotionProcessType)
              "
              class="ml-2"
            >
              {{
                localizeStatus(promotionProcessType, item.promotionFormStatus)
              }}
            </v-chip>
          </v-col>
          <v-col cols="12" md="7">
            <v-btn
              v-if="!item.promotionFormSubmitted"
              small
              color="secondary"
              :to="{
                name: 'FeedbackForm',
                params: { id: item.promotionFormFeedbackId }
              }"
            >
              <v-icon class="mr-2">mdi-pencil-outline</v-icon>
              {{ $t('feedbacks.goToForm') }}
            </v-btn>

            <template v-else-if="item.promotionFormSubmitted">
              <div>
                <v-btn
                  small
                  :to="{
                    name: 'FeedbackForm',
                    params: { id: item.promotionFormFeedbackId }
                  }"
                >
                  <v-icon small class="mr-2">mdi-clipboard-check</v-icon>
                  {{ $t('careerDevelopment.goToResult') }}
                </v-btn>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { careerDevelopmentEnums } from '@/enums/careerDevelopment.js';
import { mapActions } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

import processMixins from '@/mixins/feedback-process.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [processMixins],

  data: () => ({
    expanded: false,
    hardfactSheetMode: careerDevelopmentEnums.hardfactSheetMode,
    promotionFormMode: careerDevelopmentEnums.promotionFormMode
  }),

  computed: {
    promotionButtonEnabled() {
      if (
        this.item.hardfactSheetMode === this.hardfactSheetMode.MANDATORY &&
        this.item.hardfactsFulfilled === null
      ) {
        return false;
      }

      if (
        this.item.promotionFormMode === this.promotionFormMode.MANDATORY &&
        !this.item.promotionFormSubmitted
      ) {
        return false;
      }

      return true;
    },

    mafegProcessType() {
      return feedbackEnums.processTypes.MAFEG;
    },

    promotionProcessType() {
      return feedbackEnums.processTypes.PROMOTION;
    }
  },

  methods: {
    ...mapActions({
      confirmPromotion: 'careerDevelopment/confirmPromotion',
      denyPromotion: 'careerDevelopment/denyPromotion',
      setHardfactsState: 'careerDevelopment/setHardfactsState'
    }),

    changeHardfactsState() {
      this.setHardfactsState({
        processId: this.item.id,
        state: this.item.hardfactsFulfilled
      });
    },

    confirm() {
      this.confirmPromotion(this.item.id);
      this.item.resolution = 1;
    },

    deny() {
      this.denyPromotion(this.item.id);
      this.item.resolution = 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.promotion-process__item-row:hover {
  cursor: pointer;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}

::v-deep .v-input--radio-group {
  label,
  i.v-icon {
    color: var(--textcolor-subtle);
  }

  label {
    font-size: 0.875rem;
  }
}
</style>
