export const careerDevelopmentEnums = Object.freeze({
  hardfactSheetMode: {
    NONE: 0,
    OPTIONAL: 1,
    MANDATORY: 2
  },

  promotionFormMode: {
    NONE: 0,
    OPTIONAL: 1,
    MANDATORY: 2
  }
});
