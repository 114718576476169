<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title class="d-block">
        <h5>Nominierungen für halbjährliche Beförderungen</h5>
        <v-subheader class="pl-0"
          >Zum nächsten Beförderungszeitpunkt am
          {{ currentTargetDate }}</v-subheader
        >
      </v-card-title>
      <v-card-text class="pt-0">
        <v-alert type="info" text class="mt-0">
          <ul>
            <li>
              Die Beförderungsrunde zum April 2023 startet am 14.01. und endet
              am 24.02.2023. Ab XX.XX.XXXX eob. kann nichts mehr in der Software
              eingetragen werden.
            </li>
            <li>
              Die hier eingetragenen Mitarbeitenden erscheinen auf der finalen
              Beförderungsliste, solange von der Berichtslinie aufwärts kein
              Veto eingelegt wird.
            </li>
            <li>
              Für die Nominierungen zum (S)AL und Senior Expert muss das
              entsprechende Nominierungsformular abgelegt werden.
            </li>
            <li>
              Bitte beachtet in der
              <a
                href="https://haysonline.sharepoint.com/sites/People-Culture/PE/SitePages/Prozessablauf-und-Gremienstruktur.aspx"
                target="_blank"
                >Gremienstruktur</a
              >
              welche Positionen in welchem Gremium besprochen u. entschieden
              werden.
            </li>
            <li>
              Beförderungen zum BL, Dir., MD, ManExp sind jährlich nur zum
              01.04. möglich.
            </li>
            <li>
              Informationen zum halbjährlichen Beförderungsprozess findet ihr
              <a
                href="https://haysonline.sharepoint.com/sites/People-Culture/PE/SitePages/Bef%C3%B6rderungsprozess.aspx"
                target="_blank"
                >hier</a
              >.
            </li>
            <li>
              Monatliche Beförderungen (bspw. KAM/S-KAM/S-ReS/S-CaS) laufen
              nicht über den halbjährlichen Beförderungsprozess u. müssen über
              das
              <a
                href="https://www.haysbohelpdesk-dach.net/Templates.do?SkipNV2Filter=true&module=incident"
                target="_blank"
                >Ticketsystem bei Employee Care</a
              >
              angestoßen werden.
            </li>
          </ul>
        </v-alert>

        <template v-if="loading">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </template>
        <template v-else>
          <process-overview v-model="currentProcesses"></process-overview>

          <div class="text-center">
            <add-process
              :talkrounds="talkrounds"
              :correlationId="correlationId"
            >
              <template v-slot:button>
                <v-icon>mdi-plus</v-icon>
                Manuelle Nominierung hinzufügen
              </template>
            </add-process>
          </div>
        </template>
      </v-card-text>
    </v-card>

    <v-card v-if="futureProcesses.length > 0" class="segmented mt-4" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>Nominierungen für zukünftige halbjährliche Beförderungen</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert type="info" text>
          Nominierungen zum nächstmöglichen Zeitpunkt findest du oben in der
          Tabelle
        </v-alert>
        <template v-if="loading">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </template>
        <process-overview
          v-else
          v-model="futureProcesses"
          :readOnly="true"
        ></process-overview>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import processOverview from '@/components/careerDevelopment/overview.vue';
import addProcess from '@/components/careerDevelopment/add-process-modal.vue';

import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    promotionUsers: [],
    talkrounds: [],
    currentTalkround: null,
    loading: true,
    correlationId: null
  }),

  computed: {
    currentProcesses() {
      const talkroundId = this.currentTalkround?.id;
      if (!talkroundId) {
        return [];
      }

      return this.promotionUsers.filter(
        (x) => x.talkroundId.toLowerCase() === talkroundId.toLowerCase()
      );
    },

    currentTargetDate() {
      if (!this.currentTalkround) {
        return '';
      }

      return this.$d(new Date(this.currentTalkround.targetDate), 'date');
    },

    futureProcesses() {
      const inactiveTalkroundIds = this.talkrounds
        .filter((x) => !x.isActive)
        .map((x) => x.id);

      return this.promotionUsers.filter((x) =>
        inactiveTalkroundIds.includes(x.talkroundId.toLowerCase())
      );
    },

    approvedProcesses() {
      return this.promotionUsers.filter((x) => x.resolution === 1);
    },

    deniedProcesses() {
      return this.promotionUsers.filter((x) => x.resolution === 2);
    }
  },

  async mounted() {
    this.promotionUsers = await this.getPromotionUsers();
    await this.setTalkrounds();

    this.setCurrentTalkround();

    this.correlationId = uuidv4();

    this.$promotionsHub.joinGroup(this.correlationId);
    this.$promotionsHub.$on('promotion-process-added', this.onProcessAdded);

    this.loading = false;
  },

  beforeDestroy() {
    this.$promotionsHub.leaveGroup(this.correlationId);
    this.$formsHub.$off('promotion-process-added', this.onProcessAdded);
  },

  methods: {
    ...mapActions('careerDevelopment', {
      getPromotionUsers: 'getPromotionUsers',
      fetchTalkrounds: 'listTalkrounds'
    }),

    setCurrentTalkround() {
      const activeTalkrounds = this.talkrounds.filter((x) => x.isActive);
      if (activeTalkrounds.length > 0) {
        this.currentTalkround = activeTalkrounds[0];
      }
    },

    async setTalkrounds() {
      if (this.talkrounds.length === 0) {
        const talkrounds = await this.fetchTalkrounds();
        const talkroundOptions = talkrounds.map((x) => {
          const endDate = new Date(x.endDateUtc);
          const monthName = this.$t(
            `dashboard.months.${endDate.getMonth() + 1}`
          );

          return {
            value: x.id,
            text: `${monthName} ${endDate.getFullYear()}`,
            targetDate: endDate,
            isActive: x.isActive,
            id: x.id
          };
        });

        this.talkrounds = talkroundOptions;
      }
    },

    onProcessAdded(data) {
      if (this.correlationId !== data.correlationId) {
        return;
      }

      this.promotionUsers.push(data.promotionUserData);
    }
  },

  components: {
    processOverview,
    addProcess
  }
};
</script>
